import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
} from "react-admin";
import { thumbUrl } from "../../common";

const SubOrderList = (props) => {
  const params = {
    basePath: "/sub_orders",
    resource: "sub_orders",
    location: props.location,
    hasCreate: false,
    hasEdit: true,
    hasList: true,
    hasShow: false,
    match: props.match,
  };
  return (
    <List
      {...params}
      filterDefaultValues={{ order_id: props.id }}
      bulkActions={false}
    >
      <Datagrid>
        <ReferenceField source="product_id" reference="products">
          <FunctionField
            label="Image"
            render={(record) =>
              record.image && (
                <img
                  src={thumbUrl(record.image, 350)}
                  alt={record.supc}
                  width="100px"
                />
              )
            }
          />
        </ReferenceField>
        <ReferenceField source="product_id" reference="products">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="quantity" />
        <TextField source="total" />
      </Datagrid>
    </List>
  );
};

export default SubOrderList;
