import React from "react";
import {
  TextField,
  Edit,
  TextInput,
  SimpleForm,
  required,
  TopToolbar,
} from "react-admin";
import ResetPassword from "./resetPassword";

const UserEditActions = (props) => (
  <TopToolbar>
    <ResetPassword label="Reset Password" {...props} />
  </TopToolbar>
);
export default (props) => (
  <Edit {...props} undoable={false} actions={<UserEditActions />}>
    <SimpleForm>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="country" />
      <TextField source="zipcode" />
      <TextInput source="is_active" validate={required()} />
    </SimpleForm>
  </Edit>
);
