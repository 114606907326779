import React from "react";
import { List, Datagrid, TextField, Filter, DateInput } from "react-admin";

import Icon from "@material-ui/icons/ViewList";

const ReportFilter = (props) => (
  <Filter {...props}>
    <DateInput source="gtd" label="From" alwaysOn />
    <DateInput source="ltd" label="To" alwaysOn />
  </Filter>
);

const ReportList = (props) => (
  <List {...props} perPage={99999} filters={<ReportFilter />} empty={false}>
    <Datagrid>
      <TextField source="id" label="#Order" />
      <TextField source="email" label="Email" />
      <TextField source="billing_state" label="State" />
      <TextField source="total" label="Total With Tax" />
      <TextField source="totalw" label="Total W/O Tax" />
      <TextField source="IGST" label="IGST" />
      <TextField source="GST" label="SGST" />
      <TextField source="GST" label="CGST" />
    </Datagrid>
  </List>
);

export default {
  label: "Tax Report",
  options: { label: "Tax Report" },
  name: "tax_report",
  list: ReportList,
  icon: Icon,
};
