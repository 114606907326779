import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  Filter,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput source="user_id" reference="users" alwaysOn>
      <AutocompleteInput optionText={(choice) => choice.name} />
    </ReferenceInput>
  </Filter>
);

export default (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="from" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="to" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="amount" />
      <TextField source="remarks" />
    </Datagrid>
  </List>
);
