import React from 'react';
import { List, Datagrid, TextField, Edit, Create, TextInput, SimpleForm, required, EditButton } from 'react-admin';
import Icon from '@material-ui/icons/Dehaze';

const CategoryList = props => (
	<React.Fragment>
		<List {...props}>
			<Datagrid>
				<TextField source="name" />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);

const CategoryCreate = props => (
	<Create {...props} undoable={false}>
		<SimpleForm redirect={`/categories`}>
			<TextInput source="name" validate={required()} />
		</SimpleForm>
	</Create>
);

const CategoryEdit = props => (
	<Edit {...props} undoable={false}>
		<SimpleForm redirect={`/categories`}>
			<TextInput source="name" validate={required()} />
		</SimpleForm>
	</Edit>
);

export default {
	list: CategoryList,
	edit: CategoryEdit,
	create: CategoryCreate,
	icon: Icon,
	label: 'Categories',
	options: { label: 'Categories' },
	name: 'categories',
};
