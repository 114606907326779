import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  Filter,
  DateInput,
  downloadCSV,
} from "react-admin";

import jsonExport from "jsonexport/dist";
import Icon from "@material-ui/icons/ViewList";

const ReportFilter = (props) => (
  <Filter {...props}>
    <DateInput source="gtd" label="From" alwaysOn />
    <DateInput source="ltd" label="To" alwaysOn />
  </Filter>
);

const exporter = (rows) => {
  let total = 0;
  let totalBv = 0;
  const rowsForExport = rows.map((data) => {
    total += data.total;
    totalBv += data.total_bv;
    return data;
  });
  const totalRow = {
    id: "",
    user_id: "",
    billing_name: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    payment_status: "",
    order_status: "",
    created_at: "TOTAL",
    total: total,
    total_bv: totalBv,
  };
  rowsForExport.push(totalRow);

  jsonExport(
    rowsForExport,
    {
      headers: [
        "id",
        "user_id",
        "billing_name",
        "billing_city",
        "billing_state",
        "billing_country",
        "payment_status",
        "order_status",
        "created_at",
        "total",
        "total_bv",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "sales_report"); // download as 'posts.csv` file
    }
  );
};

const ReportList = (props) => (
  <List
    {...props}
    perPage={99999}
    filters={<ReportFilter />}
    exporter={exporter}
    empty={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="#Order" />
      <ReferenceField
        source="user_id"
        reference="vendors"
        label="Vendor"
        linkType={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <NumberField source="total" label="Total" />
      <TextField source="order_status" label="Order Status" />
      <TextField source="created_at" label="Date" />
    </Datagrid>
  </List>
);

export default {
  label: "Sales Report",
  options: { label: "Sales Report" },
  name: "sales_report",
  list: ReportList,
  icon: Icon,
};
