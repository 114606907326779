import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  DateInput,
  downloadCSV,
  ReferenceField,
} from "react-admin";
import jsonExport from "jsonexport/dist";

import Icon from "@material-ui/icons/ViewList";

const ReportFilter = (props) => (
  <Filter {...props}>
    <DateInput source="gtd" label="From" alwaysOn />
    <DateInput source="ltd" label="To" alwaysOn />
  </Filter>
);
const exporter = (rows) => {
  let total = 0;
  const rowsForExport = rows.map((data) => {
    total += data.total;
    return data;
  });
  const totalRow = {
    id: "",
    vendor: "TOTAL",
    email: "",
    total: total,
  };
  rowsForExport.push(totalRow);

  jsonExport(
    rowsForExport,
    {
      headers: ["id", "vendor", "email", "total"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "purchase_report"); // download as 'posts.csv` file
    }
  );
};

const ReportList = (props) => (
  <List
    {...props}
    perPage={99999}
    filters={<ReportFilter />}
    exporter={exporter}
    empty={false}
  >
    <Datagrid rowClick="edit">
      <ReferenceField label="Name" source="id" reference="vendors">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Email" source="id" reference="vendors">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="total" />
    </Datagrid>
  </List>
);

export default {
  label: "Purchase Report",
  options: { label: "Purchase Report" },
  name: "purchase_report",
  list: ReportList,
  icon: Icon,
};
