import List, { ProductListAll } from "./list";

import Edit from "./edit";
import Create from "./create";
import Icon from "@material-ui/icons/CheckBoxOutlineBlank";
import ProductListIcon from "@material-ui/icons/CheckCircle";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Icon,
  label: "Products",
  options: { label: "Products" },
  name: "products",
};

export const productList = {
  list: ProductListAll,
  icon: ProductListIcon,
  label: "Products List",
  options: { label: "Products List" },
  name: "products_list",
};
