import List from './list';
import Edit from './edit';
import Create from './create';
import Icon from '@material-ui/icons/PersonAdd';

export default {
	list: List,
	edit: Edit,
	create: Create,
	icon: Icon,
	label: 'Admin',
	options: { label: 'Admin' },
	name: 'admins',
};
