import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  DateInput,
  downloadCSV,
} from "react-admin";

import Icon from "@material-ui/icons/ViewList";

import jsonExport from "jsonexport/dist";
const exporter = (rows) => {
  let total = 0;
  const rowsForExport = rows.map((data) => {
    total += data.amount;
    return data;
  });
  const totalRow = {
    id: "",
    vendor: "TOTAL",
    amount: total,
  };
  rowsForExport.push(totalRow);
  jsonExport(
    rowsForExport,
    {
      headers: ["id", "vendor", "amount"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "comm_report"); // download as 'posts.csv` file
    }
  );
};
const ReportFilter = (props) => (
  <Filter {...props}>
    <DateInput source="gtd" label="From" alwaysOn />
    <DateInput source="ltd" label="To" alwaysOn />
  </Filter>
);

export const ReportList = (props) => (
  <List
    {...props}
    perPage={99999}
    filters={<ReportFilter />}
    exporter={exporter}
    empty={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="vendor" />
      <TextField source="amount" />
    </Datagrid>
  </List>
);

export default {
  label: "Comm Report",
  options: { label: "Comm Report" },
  name: "comm_report",
  list: ReportList,
  icon: Icon,
};
