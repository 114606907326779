import React from "react";
import {
  ReferenceField,
  TextField,
  NumberField,
  DateInput,
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import WebSubOrderList from "./subOrders";

// import Basket from './Basket';

const editStyles = {
  root: { alignItems: "flex-start" },
};

const OrderEdit = (props) => (
  <Edit {...props} aside={<WebSubOrderList {...props} />}>
    <SimpleForm>
      <SelectInput
        source="order_status"
        choices={[
          { id: "PENDING", name: "PENDING" },
          { id: "PREPARE", name: "PREPARE" },
          { id: "PACK", name: "PACK" },
          { id: "DISPATCH", name: "DISPATCH" },
        ]}
      />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="sub_total" />
      <NumberField source="shipping" />
      <NumberField source="discount" />
      <NumberField source="total" />
      <NumberField source="bv_total" />
      <TextInput source="tracking" />
      <TextField source="payment_status" />
      <DateInput source="shipped_at" />
    </SimpleForm>
  </Edit>
);

export default withStyles(editStyles)(OrderEdit);
