import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  NumberField,
} from "react-admin";

export default (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ImageField source="thumbnail" title="thumbnail" />
      <TextField source="name" />
      <TextField source="location" />
      <NumberField source="height" />
      <NumberField source="width" />
    </Datagrid>
  </List>
);
