import List from "./list";
import Edit from "./edit";
import Icon from "@material-ui/icons/LocalGroceryStore";
// import Show from "./show";

export default {
  list: List,
  edit: Edit,
  // show: Show,
  icon: Icon,
  label: "Web Orders",
  options: { label: "Web Orders" },
  name: "web_orders",
};
