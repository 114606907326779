import React from "react";
import { useState, createElement } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink } from "react-admin";
import ProductIcon from "@material-ui/icons/Apps";
import AdminIcon from "@material-ui/icons/People";
import TransactionIcon from "@material-ui/icons/Computer";
import ReportIcon from "@material-ui/icons/InsertChart";

import SubMenu from "./subMenu";

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    enoch: true,
    jaali: true,
  });
  const resources = useSelector((state) => state.admin.resources);

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const Submenu = [
    {
      label: "Orders",
      name: "orders",
      icon: AdminIcon,
      data: ["orders", "web_orders"],
    },
    {
      label: "Transactions",
      name: "transactions",
      icon: TransactionIcon,
      data: [
        "user_transactions",
        "vendor_transactions",
        "wallet_requests",
        "vendors_balance",
      ],
    },
    {
      label: "Users",
      name: "users",
      icon: AdminIcon,
      data: ["vendors", "admins", "users", "bhds"],
    },
    {
      label: "Products",
      name: "products",
      icon: ProductIcon,
      data: ["categories", "sub_categories", "products", "products_list"],
    },
    {
      label: "Reports",
      name: "reports",
      icon: ReportIcon,
      data: [
        "tax_report",
        "sales_report",
        "purchase_report",
        "payment_report",
        "stock_order_report",
        "comm_report",
        "bhd_comm_report",
      ],
    },
  ];

  const menus = ["advertisements", "blogs", "settings"];

  return (
    <div>
      {Submenu.map((sub, index) => (
        <SubMenu
          handleToggle={() => handleToggle(sub.name)}
          isOpen={state[sub.name]}
          sidebarIsOpen={open}
          name={sub.label}
          icon={createElement(sub.icon)}
          dense={dense}
          key={index}
        >
          {Object.keys(resources).length > 0 &&
            sub.data
              .filter((resource) => resources[resource].props.hasList)
              .map((resource) => (
                <MenuItemLink
                  key={resources[resource].props.name}
                  to={`/${resources[resource].props.name}`}
                  primaryText={resources[resource].props.options.label}
                  leftIcon={createElement(resources[resource].props.icon)}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              ))}
        </SubMenu>
      ))}
      {menus.map(
        (resource) =>
          resources[resource] && (
            <MenuItemLink
              key={resources[resource].props.name}
              to={`/${resources[resource].props.name}`}
              primaryText={resources[resource].props.options.label}
              leftIcon={createElement(resources[resource].props.icon)}
              onClick={onMenuClick}
            />
          )
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
