import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  EditButton,
  NumberField,
} from "react-admin";

export default (props) => {
  return (
    <Datagrid {...props} rowClick="edit">
      <TextField source="id" />
      <DateField source="updated_at" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="sub_total" />
      <TextField source="shipping" />
      <TextField source="discount" />
      <NumberField
        source="total"
        options={{
          style: "currency",
          currency: "INR",
        }}
      />
      <TextField source="tracking" />
      <EditButton />
    </Datagrid>
  );
};
