import React from "react";
import {
  Create,
  NumberInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  FormDataConsumer,
  BooleanInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

export default (props) => (
  <Create {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Details">
        <ReferenceInput
          source="category_id"
          reference="categories"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.category_id && (
              <ReferenceInput
                source="sub_category_id"
                reference="sub_categories"
                filter={{ categories_id: formData.category_id }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <TextInput source="title" validate={required()} fullWidth />
        <TextInput source="supc" validate={required()} />
        <NumberInput source="mrp" validate={required()} />
        <NumberInput source="bv_value" />
        <NumberInput source="rp" label="Retail Profit" validate={required()} />
        <NumberInput source="gst" label="Tax Provision" validate={required()} />
        <NumberInput source="cp" label="Input Cost" validate={required()} />
        <NumberInput source="discount" validate={required()} />
        <NumberInput source="stock" validate={required()} />
        <BooleanInput source="is_active" />
      </FormTab>
      <FormTab label="Image">
        <ImageInput
          source="image"
          label="Image"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Description">
        <RichTextInput source="short_description" validate={required()} />
        <RichTextInput source="detailed_description" validate={required()} />
      </FormTab>
      <FormTab label="Logistics">
        <NumberInput source="height" validate={required()} />
        <NumberInput source="width" validate={required()} />
        <NumberInput source="length" validate={required()} />
        <NumberInput source="weight" validate={required()} />
      </FormTab>
    </TabbedForm>
  </Create>
);
