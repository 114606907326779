import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SearchInput,
  Filter,
  SelectInput,
  ReferenceField,
  NumberField,
  EditButton,
  Edit,
  SimpleForm,
  required,
  TextInput,
  NumberInput,
} from "react-admin";
import VendorBalanceButton from '../../components/vendorBalanceButton';

const VendorFilters = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SearchInput source="name" />
    <SearchInput source="email" />
    <SearchInput source="phone" />
    <SearchInput source="ref" />
    <SearchInput source="bhd" />
    <SearchInput source="city" />
    <SearchInput source="country" />
    <SearchInput source="state" />
    <SelectInput
      source="is_approved"
      choices={[
        { id: "0", name: "Not Approved" },
        { id: "1", name: "Approved" },
      ]}
    />
    <SelectInput
      source="is_bhd"
      choices={[
        { id: "0", name: "No" },
        { id: "1", name: "Yes" },
      ]}
    />
    <SelectInput
      source="level"
      choices={[
        { id: "BRONZE", name: "BRONZE" },
        { id: "SILVER", name: "SILVER" },
        { id: "GOLD", name: "GOLD" },
        { id: "DIAMOND", name: "DIAMOND" },
      ]}
    />
  </Filter>
);

export default (props) => (
  <List {...props} filters={<VendorFilters />}>
    <Datagrid>
      <TextField source="email" />
      <TextField source="level" />
      <ReferenceField source="bhd_id" reference="vendors" allowEmpty>
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="ref_id" reference="vendors" allowEmpty>
        <TextField source="email" />
      </ReferenceField>
      <NumberField source="balance" />
      <TextField source="status" />
      <TextField source="is_approved" />
      <DateField source="updated_at" />
      <EditButton />
      <VendorBalanceButton />
    </Datagrid>
  </List>
);

export const VendorEditBalance = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="/vendors">
      <TextField source="email" />
      <TextField
        source="balance"
        label="Available Balance"
        validate={required()}
      />
      <NumberInput
        source="edit_balance"
        label="Edit Balance"
        validate={required()}
      />
      <SelectInput
        source="edit_type"
        choices={[
          { id: "CREDIT", name: "CREDIT" },
          { id: "DEBIT", name: "DEBIT" },
        ]}
        validate={required()}
      />
      <TextInput source="remarks" validate={required()} />
    </SimpleForm>
  </Edit>
);
