import List from "./list";
import Show from "./show";
import Create from "./create";

import Icon from "@material-ui/icons/AccountBalanceWallet";

export default {
  list: List,
  create: Create,
  icon: Icon,
  show: Show,
  label: "Wallet Request",
  options: { label: "Wallet Request" },
  name: "wallet_requests",
};
