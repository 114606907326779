import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  NumberField,
} from "react-admin";

export default (props) => {
  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="updated_at" />
      <ReferenceField source="user_id" reference="vendors">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="total_bv" />
      <TextField source="sub_total" />
      <TextField source="shipping" />
      <TextField source="discount" />
      <NumberField
        source="total"
        options={{
          style: "currency",
          currency: "INR",
        }}
        // className={classes.total}
      />
      <TextField source="tracking" />
      <NumberField source="weight" />
      <EditButton />
    </Datagrid>
  );
};
