import Bhd_comm_reportList from "./bhdCommReport";
import Comm_reportList from "./commReport";
import Payment_reportList from "./paymentReport";
import Purchase_reportList from "./purchaseReport";
import Sales_reportList from "./salesReport";
import Stock_order_reportList from "./stockOrderReport";
import Tax_reportList from "./taxReport";

export const bhdCommReport = Bhd_comm_reportList;
export const commReport = Comm_reportList;
export const paymentReport = Payment_reportList;
export const purchaseReport = Purchase_reportList;
export const salesReport = Sales_reportList;
export const stockOrderReport = Stock_order_reportList;
export const taxReport = Tax_reportList;
