import React from "react";
import { Admin, Resource } from "react-admin";

import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import withClearCache from "./ClearCache";

import dataProvider from "./dataProvider";
import authProvider from "./authProvider";

import advertisement from "./containers/advertisement/index";
import admin from "./containers/admin/index";
// import product from "./containers/product/index";
import order from "./containers/order";
import webOrder from "./containers/webOrder";
import setting from "./containers/setting";
import bhd from "./containers/bhd";
import product, { productList } from "./containers/product";
import walletRequest from "./containers/walletRequest";
import user, { userTransaction } from "./containers/user";
import vendor, { vendorBalance, vendorTransaction } from "./containers/vendor";
import {
  bhdCommReport,
  commReport,
  purchaseReport,
  paymentReport,
  taxReport,
  stockOrderReport,
  salesReport,
} from "./containers/reports";
import blog from "./containers/blog";
import category from "./containers/category";
import subCategory from "./containers/subCategory/index";
import layout from "./layout";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const MainApp = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    // theme={myTheme}
    layout={layout}
  >
    {(permissions) => [
      <Resource {...order} />,
      <Resource name="sub_orders" />,
      <Resource {...webOrder} />,
      <Resource name="web_sub_orders" />,
      <Resource {...product} />,
      <Resource {...productList} />,
      <Resource {...category} />,
      <Resource {...subCategory} />,
      <Resource {...blog} />,
      <Resource {...admin} />,
      <Resource {...user} />,
      <Resource {...bhd} />,
      <Resource {...userTransaction} />,
      <Resource {...vendor} />,
      <Resource {...vendorBalance} />,
      <Resource {...vendorTransaction} />,
      <Resource {...setting} />,
      <Resource {...bhdCommReport} />,
      <Resource {...commReport} />,
      <Resource {...purchaseReport} />,
      <Resource {...paymentReport} />,
      <Resource {...taxReport} />,
      <Resource {...stockOrderReport} />,
      <Resource {...salesReport} />,
      <Resource {...walletRequest} />,
      <Resource {...advertisement} />,
    ]}
  </Admin>
);

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export default App;
