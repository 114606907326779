import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SelectInput,
  ReferenceInput,
  NumberInput,
} from "react-admin";

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" validate={required()} />
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="password" validate={required()} />
      <NumberInput source="phone" validate={required()} />
      <TextInput source="address" validate={required()} />
      <TextInput source="city" validate={required()} />
      <TextInput source="state" validate={required()} />
      <TextInput source="country" validate={required()} />
      <NumberInput source="zipcode" validate={required()} />
      <ReferenceInput
        label="BHD"
        source="bhd_id"
        reference="vendors"
        filter={{ is_bhd: 1 }}
        validate={required()}
      >
        <SelectInput optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        label="Refferer"
        source="ref_id"
        reference="vendors"
        validate={required()}
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
      <NumberInput source="balance" validate={required()} />
      <SelectInput
        source="level"
        choices={[
          { id: "BRONZE", name: "BRONZE" },
          { id: "SILVER", name: "SILVER" },
          { id: "GOLD", name: "GOLD" },
          { id: "DIAMOND", name: "DIAMOND" },
        ]}
      />
      <TextInput source="is_active" validate={required()} />
    </SimpleForm>
  </Create>
);

const optionRenderer = (choice) => `${choice.name} - ${choice.city}`;
