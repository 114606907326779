import React from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  Filter,
  DateInput,
} from "react-admin";
import Icon from "@material-ui/icons/ViewList";

const ReportFilter = (props) => (
  <Filter {...props}>
    <DateInput source="gtd" label="From" alwaysOn />
    <DateInput source="ltd" label="To" alwaysOn />
  </Filter>
);

const ReportList = (props) => (
  <List {...props} perPage={99999} filters={<ReportFilter />} empty={false}>
    <Datagrid rowClick="edit">
      <ReferenceField
        source="id"
        reference="products"
        label="Product"
        linkType={false}
      >
        <TextField source="title" />
      </ReferenceField>

      <TextField source="total_quantity" label="Quantity" />
      <NumberField source="total_price" label="Price" />
    </Datagrid>
  </List>
);

export default {
  label: "Stock Order Report",
  options: { label: "Stock Order Report" },
  name: "stock_order_report",
  list: ReportList,
  icon: Icon,
};
