import React from "react";
import { List, Datagrid, TextField, DateField, EmailField } from "react-admin";
import menu from "./menu";

import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
});
const AccessField = withStyles(styles)(({ classes, record = {} }) =>
  record.access
    ? record.access.map((acc, index) => (
        <Chip
          label={menu.find((o) => o.id === acc).name}
          className={classes.chip}
          key={index}
        />
      ))
    : null
);

export default (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="mobile" />
      <AccessField AccessField />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);
