import React from "react";
import { Button, useNotify, useRefresh, useUpdate } from "react-admin";

function ResetPassword({ data }) {
  const [update] = useUpdate("users");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = () => {
    update(
      { payload: { id: data.id, data: { ...data, change_password: 1 } } },
      {
        onSuccess: () => {
          refresh();
        },
        onFailure: ({ err }) => {
          notify(err.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleSubmit} label="Reset Password" />
    </>
  );
}

export default ResetPassword;
