import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Filter,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput source="vendor_id" reference="vendors" alwaysOn>
      <AutocompleteInput optionText={(choice) => choice.email} />
    </ReferenceInput>
  </Filter>
);

export default (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="vendor" />
      <TextField source="order_id" />
      <TextField source="from" />
      <TextField source="to" />
      <TextField source="amount" />
      <TextField source="bv" label="BV/Difference" />
      <TextField source="balance" />
      <TextField source="remarks" />
      <TextField source="created_at" />
      <TextField source="updated_at" />
    </Datagrid>
  </List>
);
