import OrderList from "./list";
import Edit from "./edit";
import Icon from "@material-ui/icons/LocalGroceryStore";
// import Show from "./show";

export default {
  list: OrderList,
  edit: Edit,
  // show: Show,
  icon: Icon,
  label: "Orders",
  options: { label: "Orders" },
  name: "orders",
};
