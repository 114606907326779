import React from "react";
import {
  TextField,
  NumberField,
  Show,
  required,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  TopToolbar,
} from "react-admin";
import ApproveDeclineButton from "../../components/approveDeclineButton";
import { thumbUrl } from "../../common";

const WalletShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ApproveDeclineButton
      record={data}
      color="green"
      label="APPROVE"
      status="APPROVED"
    />
    <ApproveDeclineButton
      record={data}
      color="red"
      label="DECLINE"
      status="DECLINED"
    />
  </TopToolbar>
);

export default (props) => (
  <Show actions={<WalletShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField source="vendor_id" reference="vendors">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Redeem Type"
        render={(record) =>
          record.bhd_redeem === 1
            ? `${record.bhd_redeem_type} - ${record.remarks}`
            : record.remarks
        }
      />
      <NumberField source="amount" validate={required()} />

      <FunctionField
        label="Image"
        render={(record) =>
          record.image && (
            <img src={thumbUrl(record.image, 350)} alt={record.supc} />
          )
        }
      />
      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
);
