import React from "react";
import {
  TextField,
  Create,
  TextInput,
  SimpleForm,
  required,
} from "react-admin";

export default (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="country" />
      <TextField source="zipcode" />
      <TextInput source="is_active" validate={required()} />
    </SimpleForm>
  </Create>
);
