import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  ReferenceField,
  NumberField,
  FunctionField,
  ShowButton,
} from "react-admin";
import { thumbUrl } from "../../common";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput source="vendor_id" reference="vendors" alwaysOn>
      <AutocompleteInput optionText={(choice) => choice.email} />
    </ReferenceInput>
  </Filter>
);

export default (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick="show">
      <ReferenceField source="vendor_id" reference="vendors">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="vendor_id" reference="vendors">
        <TextField source="email" />
      </ReferenceField>
      <FunctionField
        label="Image"
        render={(record) =>
          record.image && (
            <img
              src={thumbUrl(record.image, 350)}
              alt={record.supc}
              width="100px"
            />
          )
        }
      />
      <TextField source="status" />
      <NumberField source="amount" />
      <FunctionField
        label="Remarks"
        render={(record) =>
          record.bhd_redeem === 1
            ? `${record.bhd_redeem_type} - ${record.remarks}`
            : record.remarks
        }
      />
      <DateField
        label="Created At"
        source="created_at"
        render={(record) => record.created_at}
      />
      <ShowButton />
    </Datagrid>
  </List>
);
