import List from './list';
import Edit from './edit';
import Create from './create';
import Icon from '@material-ui/icons/Person';
import TransactionsList from "./userTransaction";
import TransactionsIcon from "@material-ui/icons/AccountBox";

export default {
	list: List,
	edit: Edit,
	create: Create,
	icon: Icon,
	label: 'Users',
	options: { label: 'Users' },
	name: 'users',
};


export const userTransaction = {
	list: TransactionsList,
	label: "Users Transactions",
	options: { label: "Users Transactions" },
	name: "user_transactions",
	icon: TransactionsIcon,
  };
  