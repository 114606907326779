import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ReferenceField,
} from "react-admin";

const SubOrderList = (props) => {
  const params = {
    basePath: "/web_sub_orders",
    resource: "web_sub_orders",
    location: props.location,
    hasCreate: false,
    hasEdit: true,
    hasList: true,
    hasShow: false,
    match: props.match,
  };
  return (
    <List
      {...params}
      filterDefaultValues={{ order_id: props.id }}
      bulkActions={false}
    >
      <Datagrid>
        <ReferenceField source="product_id" reference="products">
          <ImageField source="thumbnail" />
        </ReferenceField>
        <ReferenceField source="product_id" reference="products">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="quantity" />
        <TextField source="total" />
      </Datagrid>
    </List>
  );
};

export default SubOrderList;
