import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import Icon from "@material-ui/icons/People";

export const BhdList = (props) => (
  <List {...props} filterDefaultValues={{ is_bhd: 1 }}>
    <Datagrid expand={<BhdEdit />}>
      <TextField source="name" />
      <TextField source="city" />
      <TextField source="state" />
    </Datagrid>
  </List>
);

export const BhdEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="id" reference="bhds" label="New Bhd">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default {
  label: "Bhd",
  options: { label: "BHD" },
  name: "bhds",
  list: BhdList,
  icon: Icon,
};
