import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  FunctionField,
  ReferenceField,
  Filter,
  SearchInput,
} from "react-admin";
import { thumbUrl } from "../../common";
import Icon from "@material-ui/icons/FiberManualRecord";

const ProductsFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);
const BulkActions = (props) => <React.Fragment></React.Fragment>;

export default (props) => (
  <List
    {...props}
    filters={<ProductsFilter />}
    bulkActionButtons={<BulkActions />}
  >
    <Datagrid rowClick="edit">
      <FunctionField
        label="Image"
        render={(record) =>
          record.image && (
            <img
              src={thumbUrl(record.image, 350)}
              alt={record.supc}
              width="100px"
            />
          )
        }
      />
      <ReferenceField
        label="Category"
        source="category_id"
        reference="categories"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Sub-Category"
        source="sub_category_id"
        reference="sub_categories"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <NumberField source="mrp" />
      <NumberField source="bv" />
      <NumberField source="stock" />
      <NumberField source="discount" />
      <TextField source="sellingPrice" />
      <FunctionField
        label="Active"
        source="is_active"
        render={(record) =>
          record.is_active ? (
            <Icon style={{ color: "green" }} />
          ) : (
            <Icon style={{ color: "red" }} />
          )
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProductListAll = (props) => (
  <List
    {...props}
    perPage={500}
    filters={<ProductsFilter />}
    bulkActionButtons={<BulkActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <NumberField source="stock" />
      <TextField source="sellingPrice" />
      <NumberField source="bv" />
      <NumberField source="mrp" />
    </Datagrid>
  </List>
);
