import React from "react";
import SettingsIcon from "@material-ui/icons/Create";

import { Button, useRedirect } from "react-admin";

function VendorBalanceButton({ record }) {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect(`/vendors_balance/${record.id}`);
  };

  return (
    <Button label="Bal" onClick={handleClick}>
      <SettingsIcon />
    </Button>
  );
}

export default VendorBalanceButton;
