import { apiUrl } from "./constants";

const authProvider = {
  login: (params) => {
    const { username, password } = params;
    const request = new Request(`${apiUrl}/loginv2`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const access_token = JSON.parse(response.data).access_token;
        localStorage.setItem("admin_token", access_token);
        localStorage.setItem("access", response.user.access);
      });
  },

  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem("admin_token") ? Promise.resolve() : Promise.reject(),

  getPermissions: () => {
    const access = localStorage.getItem("access");
    return access ? Promise.resolve(access) : Promise.reject();
  },
};

export default authProvider;
