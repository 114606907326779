import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	Edit,
	Create,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	ReferenceField,
	required,
	EditButton,
} from 'react-admin';
import Icon from '@material-ui/icons/ViewComfy';

const SubCategoryList = props => (
	<List {...props}>
		<Datagrid rowClick="edit">
			<ReferenceField source="category_id" reference="categories">
				<TextField source="name" />
			</ReferenceField>
			<TextField source="name" />
			<EditButton />
		</Datagrid>
	</List>
);

const SubCategoryCreate = props => (
	<Create {...props} undoable={false}>
		<SimpleForm redirect={`/sub_categories`}>
			<ReferenceInput source="category_id" reference="categories" validate={required()}>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<TextInput source="name" validate={required()} />
		</SimpleForm>
	</Create>
);

const SubCategoryEdit = props => (
	<Edit {...props} undoable={false}>
		<SimpleForm redirect={`/sub_categories`}>
			<ReferenceInput source="category_id" reference="categories" validate={required()}>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<TextInput source="name" validate={required()} />
		</SimpleForm>
	</Edit>
);

export default {
	list: SubCategoryList,
	edit: SubCategoryEdit,
	create: SubCategoryCreate,
	icon: Icon,
	label: 'Sub-Category',
	options: { label: 'Sub-Category' },
	name: 'sub_categories',
};
