export default [
  { name: "Hcentre Orders", id: "orders" },
  { name: "Vendors", id: "vendors" },
  { name: "Vendor Balance Update", id: "vendors_balance" },
  { name: "Vendor Transactions", id: "vendor_transactions" },
  { name: "BHDS", id: "bhds" },
  { name: "Web Orders", id: "web_orders" },
  { name: "Web Users", id: "users" },
  { name: "Web User Transactions", id: "user_transactions" },
  { name: "Advertisements", id: "advertisements" },
  { name: "Categories", id: "categories" },
  { name: "Sub-Categories", id: "sub_categories" },
  { name: "Products", id: "products" },
  { name: "Products List", id: "products_list" },
  { name: "Blogs", id: "blogs" },
  { name: "Settings", id: "settings" },
  { name: "Wallet Requests", id: "wallet_requests" },
  { name: "Admin", id: "admins" },
  { name: "Tax Report", id: "tax_report" },
  { name: "Sales Report", id: "sales_report" },
  { name: "Purchase Report", id: "purchase_report" },
  { name: "Payment Report", id: "payment_report" },
  { name: "Stock Order Report", id: "stock_order_report" },
  { name: "Commission Report", id: "comm_report" },
  { name: "BHD Commission Report", id: "bhd_comm_report" },
];
