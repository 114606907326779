import * as React from "react";
import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";

const BulkUpdateButton = ({ resource, selectedIds, status }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    resource,
    selectedIds,
    status,
    {
      onSuccess: () => {
        refresh();
        notify("Updated");
        unselectAll(resource);
      },
      onFailure: (error) => notify("Error: Not updated", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label="Proceed" onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Update Order"
        content="Are you sure you want to update the order(s)?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BulkUpdateButton;
