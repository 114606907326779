import * as React from "react";
import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useUpdate,
  useRedirect,
} from "react-admin";

const ApproveDeclineButton = ({ record, status, label }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const recordID = record && record.id;
  const diff = { ...record, status: status };
  const [update, { loading, error }] = useUpdate(
    "wallet_requests",
    recordID,
    diff,
    record
  );

  if (error) {
    notify("Error: Wallet Request not approved", "warning");
  }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    update();
    redirect("/wallet_requests");
    refresh();
  };

  return (
    <Fragment>
      <Button label={label} onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="REQUEST"
        content={`Are you sure you want the request ${status}`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default ApproveDeclineButton;
