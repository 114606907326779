import List from "./list";
import Edit from "./edit";
import Icon from "@material-ui/icons/AddToQueue";

export default {
  list: List,
  edit: Edit,
  icon: Icon,
  label: "Advertisement",
  options: { label: "Advertisement" },
  name: "advertisements",
};
