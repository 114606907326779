import React from "react";
import {
  ReferenceField,
  TextField,
  NumberField,
  DateInput,
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import SubOrderList from "./subOrders";

// import Basket from './Basket';

const editStyles = {
  root: { alignItems: "flex-start" }
};

const OrderEdit = props => (
  <Edit {...props} aside={<SubOrderList {...props} />}>
    <SimpleForm>
      <SelectInput
        source="order_status"
        choices={[
          { id: "PENDING", name: "PENDING" },
          { id: "PREPARING", name: "PREPARING" },
          { id: "PACK", name: "PACK" },
          { id: "DISPATCH", name: "DISPATCH" }
        ]}
      />
      <ReferenceField source="user_id" reference="vendors">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="sub_total" />
      <NumberField source="shipping" />
      <NumberField source="discount" />
      <NumberField source="total" />
      <NumberField source="bv_total" />
      <TextField source="payment_status" />
      <TextInput source="irp_id" />
      <TextInput source="tracking" />
      <NumberInput source="weight" />
      <DateInput source="shipped_at" />
    </SimpleForm>
  </Edit>
);

export default withStyles(editStyles)(OrderEdit);
