import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Edit,
  Create,
  TextInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  required,
  FunctionField,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import Icon from "@material-ui/icons/Comment";
import { thumbUrl } from "../common";

export const BlogList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <FunctionField
        label="Image"
        render={(record) =>
          record.image && (
            <img
              src={thumbUrl(record.image, 350)}
              alt={record.supc}
              width="100px"
            />
          )
        }
      />
      <TextField source="title" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export const BlogCreate = (props) => (
  <Create {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Image">
        <ImageInput
          source="image"
          label="Image"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Description">
        <TextInput multiline source="title" validate={required()} fullWidth />
        <RichTextInput source="introduction" validate={required()} />
        <RichTextInput source="description" validate={required()} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const BlogEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Image">
        <FunctionField
          label="Image"
          render={(record) =>
            record.image && (
              <img
                src={thumbUrl(record.image, 350)}
                alt={record.supc}
                width="100px"
              />
            )
          }
        />
        <ImageInput
          source="image"
          label="Image"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Description">
        <TextInput multiline source="title" validate={required()} fullWidth />
        <RichTextInput source="introduction" validate={required()} />
        <RichTextInput source="description" validate={required()} />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default {
  list: BlogList,
  edit: BlogEdit,
  create: BlogCreate,
  icon: Icon,
  label: "Blogs",
  options: { label: "Blogs" },
  name: "blogs",
};
