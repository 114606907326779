import List from "./list";
import Edit from "./edit";
import Create from "./create";
import { VendorEditBalance } from "./list";
import TransactionsList from "./vendorTransaction";
import Icon from "@material-ui/icons/Person";
import TransactionsIcon from "@material-ui/icons/AccountBox";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Icon,
  label: "Vendors",
  options: { label: "Vendors" },
  name: "vendors",
};

export const vendorBalance = {
  edit: VendorEditBalance,
  name: "vendors_balance",
};

export const vendorTransaction = {
  list: TransactionsList,
  label: "Vendors Transactions",
  options: { label: "Vendors Transactions" },
  name: "vendor_transactions",
  icon: TransactionsIcon,
};
