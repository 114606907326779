import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  NumberField,
} from "react-admin";
import Icon from "@material-ui/icons/Settings";

const SettingList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="type" />
      <TextField source="name" />
      <NumberField source="value" />
      <TextField source="unit" />
    </Datagrid>
  </List>
);

const SettingCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="type" validate={required()} />
      <TextInput source="name" validate={required()} />
      <NumberInput source="value" validate={required()} />
      <TextInput source="unit" validate={required()} />
    </SimpleForm>
  </Create>
);

const SettingEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="type" validate={required()} />
      <TextInput source="name" validate={required()} />
      <NumberInput source="value" validate={required()} />
      <TextInput source="unit" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default {
  list: SettingList,
  edit: SettingEdit,
  create: SettingCreate,
  icon: Icon,
  label: "Settings",
  options: { label: "Settings" },
  name: "settings",
};
