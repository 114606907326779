import React from "react";
import { List, Datagrid, TextField, Filter, DateInput } from "react-admin";

import Icon from "@material-ui/icons/ViewList";

const ReportFilter = (props) => (
  <Filter {...props}>
    <DateInput source="date" label="Date" alwaysOn />
  </Filter>
);

const ReportList = (props) => (
  <List {...props} perPage={99999} filters={<ReportFilter />} empty={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="vendor" label="Vendor" />
      <TextField source="created_at" label="Date" />
      <TextField source="amount" label="Amount" />
      <TextField source="remarks" label="Details" />
      <TextField source="balance" label="Balance" />
    </Datagrid>
  </List>
);

export default {
  label: "Payment Report",
  options: { label: "Payment Report" },
  name: "payment_report",
  list: ReportList,
  icon: Icon,
};
