import React from "react";
import {
  Edit,
  NumberInput,
  ImageInput,
  FunctionField,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  ImageField,
  BooleanInput,
} from "react-admin";
import { withStyles } from "@material-ui/core";
import { thumbUrl } from "../../common";

const toolbarStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

export default (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Details">
        <ReferenceInput source="category_id" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.category_id && (
              <ReferenceInput
                source="sub_category_id"
                {...rest}
                reference="sub_categories"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <TextInput source="title" fullWidth />
        <NumberInput source="mrp" />
        <NumberInput source="bv_value" />
        <NumberInput source="rp" label="Retail Profit" validate={required()} />
        <NumberInput source="gst" label="Tax Provision" validate={required()} />
        <NumberInput source="cp" label="Input Cost" validate={required()} />
        <NumberInput source="discount" />
        <NumberInput source="stock" />
        <BooleanInput source="is_active" />
      </FormTab>
      <FormTab label="Image">
        <FunctionField
          label="Image"
          render={(record) =>
            record.image && (
              <img
                src={thumbUrl(record.image, 350)}
                alt={record.supc}
                width="100px"
              />
            )
          }
        />
        <ImageInput
          source="image"
          label="Image"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Description">
        <TextInput source="short_description" fullWidth />
        <TextInput source="detailed_description" fullWidth />
        <NumberInput source="discount" />
      </FormTab>
      <FormTab label="Logistics">
        <NumberInput source="height" />
        <NumberInput source="width" />
        <NumberInput source="length" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
