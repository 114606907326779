import React from "react";
import {
  TextInput,
  SimpleForm,
  required,
  SelectInput,
  ReferenceInput,
  NumberInput,
  TextField,
  Edit,
  TopToolbar,
} from "react-admin";
import ResetPassword from "./resetPassword";

const VendorEditActions = (props) => (
  <TopToolbar>
    <ResetPassword label="Reset Password" {...props} />
  </TopToolbar>
);
export default (props) => (
  <Edit {...props} actions={<VendorEditActions />} undoable={false}>
    <SimpleForm redirect="">
      <TextField source="name" validate={required()} />
      <ReferenceInput
        label="BHD"
        source="bhd_id"
        filter={{ is_bhd: 1 }}
        reference="vendors"
      >
        <SelectInput optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput label="Refferer" source="ref_id" reference="vendors">
        <SelectInput optionText="email" />
      </ReferenceInput>
      <SelectInput
        source="level"
        choices={[
          { id: "BRONZE", name: "BRONZE" },
          { id: "SILVER", name: "SILVER" },
          { id: "GOLD", name: "GOLD" },
          { id: "DIAMOND", name: "DIAMOND" },
        ]}
      />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="country" />
      <NumberInput source="zipcode" />

      <TextInput source="status" validate={required()} />
    </SimpleForm>
  </Edit>
);

const optionRenderer = (choice) => `${choice.name} - ${choice.city}`;
