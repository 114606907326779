import React from "react";
import {
  Create,
  SimpleForm,
  required,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageField,
  ImageInput,
} from "react-admin";

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="vendor_id" reference="vendors">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="amount" validate={required()} />
      <ImageField source="thumbnail" title="Thumbnail" />
      <ImageInput
        source="image"
        label="Image"
        accept="image/*"
        multiple={false}
        placeholder={<p>Drop your file here</p>}
        validate={required()}
        style={{ width: 1000 }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
