import React, { Fragment } from "react";
import {
  BulkDeleteButton,
  Filter,
  List,
  Responsive,
  TextInput,
} from "react-admin";

import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DataGrid from "./dataList";
import PDFButton from "../../components/pdfButton";

import BulkUpdateButton from "../../components/bulkUpdateButton1";

const OrderFilter = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="vendor_email" label="Email" alwaysOn />
    <TextInput source="vendor_name" label="Name" alwaysOn />
    <TextInput source="id" label="Order Id" alwaysOn />
  </Filter>
);

const tabs = [
  { id: "pending", name: "PENDING" },
  { id: "preparing", name: "PREPARING" },
  { id: "pack", name: "PACK" },
  { id: "dispatch", name: "DISPATCH" },
];

function TabbedDatagrid(props) {
  function handleChange(event, value) {
    const { filterValues, setFilters } = props;
    setFilters({ ...filterValues, status: value });
  }
  const { filterValues, resource } = props;
  const initValues =
    JSON.parse(localStorage.getItem(`${resource}_count`)) || {};
  return (
    <>
      <Tabs
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={`${choice.name} (${initValues[choice.id] || 0})`}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <Responsive medium={<DataGrid {...props} />} />
    </>
  );
}

function PostBulkActionButtons(props) {
  const { filterValues } = props;
  const status = { order_status: nextStatus(filterValues.status) };
  const currentStatus = filterValues.status.toUpperCase();
  return (
    <Fragment>
      {currentStatus === "PENDING" && <BulkDeleteButton {...props} />}
      {currentStatus === "PREPARING" && (
        <Fragment>
          <PDFButton
            label="Invoice"
            resource="orders"
            name="invoice"
            {...props}
          />
        </Fragment>
      )}
      {(currentStatus === "PACK" || currentStatus === "DISPATCH") && (
        <Fragment>
          <PDFButton
            label="Label"
            resource="orders"
            name="shipping-label"
            {...props}
          />
          <PDFButton
            label="Invoice"
            resource="orders"
            name="invoice"
            {...props}
          />
        </Fragment>
      )}
      {currentStatus !== "DISPATCH" && currentStatus !== "PENDING" && (
        <BulkUpdateButton label="Proceed" {...props} status={status} />
      )}
    </Fragment>
  );
}

const nextStatus = (current) => {
  const curr = current.toUpperCase();
  if (curr === "PENDING") return "PREPARING";
  if (curr === "PREPARING") return "PACK";
  if (curr === "PACK") return "DISPATCH";
};

export default function OrderList(props) {
  return (
    <List
      {...props}
      filterDefaultValues={{ status: tabs[0].id }}
      sort={{ field: "updated_at", order: "DESC" }}
      perPage={25}
      filters={<OrderFilter />}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <TabbedDatagrid />
    </List>
  );
}
